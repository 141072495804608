import React, { useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import ButtonBase from "../components/ButtonBase";

import * as css from "../styles/Netarts.module.scss";
import { classs } from "../utils/utils";
import CreationButton from "../components/CreationButton";

import I18n, { LocaleLink } from "../components/I18n";

const Films = ({
  pageContext: { locale, locales },
  location: { pathname },
  data: {
    strapi: {
      theme = {},
      creations: { data: creations = [] },
      artists: { data: artists = [] },
    } = {},
  },
}) => {
  const [tab, setTab] = useState(true);
  const action = (b) => () => setTab(b);

  return (
    <Layout
      pathname={pathname}
      theme={theme}
      className={classs(css.Netarts, { "in-production": !tab })}
      locale={locale}
      locales={locales}
    >
      <I18n id="title.netarts" component="h1" />
      <I18n id="netarts.description" component="p" />
      <nav>
        <button className={classs({ active: tab })} onClick={action(true)}>
          <I18n id="title.netarts.creations" component="span" />
        </button>
        <button className={classs({ active: !tab })} onClick={action(false)}>
          <I18n id="title.netarts.artists" component="span" />
        </button>
      </nav>
      {tab
        ? creations
            .filter(({ attributes: { image } }) => image)
            .map(({ id, attributes: { slug }, attributes }) => (
              <CreationButton
                key={id}
                to={`/artnum/${slug}`}
                watermark
                {...attributes}
              />
            ))
        : artists.map(
            ({ id, attributes: { slug, image, firstName, lastName } }) => (
              <ButtonBase
                component={LocaleLink}
                key={id}
                to={`/personne/${slug}`}
                className={classs(css.Person)}
              >
                <GatsbyImage
                  image={getImage(image?.data?.attributes?.fileS3)}
                />
                <p>{[firstName, lastName].filter((v) => v).join(" ")}</p>
              </ButtonBase>
            )
          )}
    </Layout>
  );
};

export const query = graphql`
  query ($locale: Strapi_I18NLocaleCode!) {
    strapi {
      artists(
        locale: $locale
        filters: { creations: { productor: { eq: "balthuslab" } } }
      ) {
        data {
          id
          attributes {
            slug
            firstName: first_name
            lastName: last_name
            image {
              data {
                id
                attributes {
                  url
                  formats
                  fileS3(minFormat: "small") {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        quality: 90
                        layout: FULL_WIDTH
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
      creations(
        locale: $locale
        sort: "release_date:desc"
        filters: { slug: { notNull: true }, productor: { eq: "balthuslab" } }
      ) {
        data {
          id
          attributes {
            slug
            releaseDate: release_date
            title
            color
            directors {
              data {
                id
                attributes {
                  firstName: first_name
                  lastName: last_name
                }
              }
            }
            image {
              data {
                id
                attributes {
                  url
                  formats
                  fileS3(minFormat: "small") {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1.5625
                        quality: 90
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Films;
